import React from "react";
import Rest from "../helpers/Rest.js";
import { withRouter } from "react-router-dom";

class VerifyBox extends React.Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);

    this.state = { email: "", submitting: false };

    if (params.get("code")) {
      this.state.code = params.get("code");
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleResend = this.handleResend.bind(this);
  }

  handleChange(event) {
    let stateDelta = {};
    stateDelta[event.target.name] = event.target.value;
    this.setState(stateDelta);
  }

  handleResend(event) {
    event.preventDefault();
    this.setState({ submitting: true });

    let self = this;
    Rest.request(
      "POST",
      "accounts/verify",
      function (status, response) {
        self.setState({ submitting: false });
        if (Rest.isSuccess(status)) {
          self.props.history.push("/login", {
            message: "Check your inbox for a verification email.",
          });
        } else {
          self.props.history.push("/login", { error: "Unexpected error." });
        }
      },
      {
        email: this.state.email,
      }
    );
  }

  componentDidMount() {
    if (this.state.code) {
      let self = this;
      Rest.request(
        "POST",
        "accounts/verify",
        function (status, response) {
          if (Rest.isSuccess(status)) {
            self.props.history.push("/login", {
              message: "Account verified! You can login now.",
            });
          } else {
            self.props.history.push("/login", {
              error: "Invalid verification code.",
            });
          }
        },
        { code: this.state.code }
      );
    }
  }

  render() {
    return (
      <div className="centre-outer">
        <div className="centre-middle">
          {this.state.code ? (
            <div className="panel spinner" id="spinner"></div>
          ) : null}
          {!this.state.code && (
            <div className="centre-inner">
              <div className="transparent-box content-box">
                <form onSubmit={this.handleResend}>
                  <input
                    name="email"
                    className="k-input"
                    type="email"
                    placeholder="e-mail"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    onChange={this.handleChange}
                    value={this.state.email}
                  />
                  <br />
                  <button type="submit" disabled={this.state.submitting}>
                    Re-send verification email!
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(VerifyBox);
