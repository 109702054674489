import React from 'react';
import Rest from '../helpers/Rest.js';
import { Link, withRouter } from 'react-router-dom';

class DeveloperBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {games: [], tab: "list", publicKey: ""};
  }

  componentDidMount() {
    this.props.setBreadcrumb('developer', true);
    this.componentDidUpdate(null);
  }

  componentWillUnmount() {
    this.props.setBreadcrumb('developer', null);
  }
  
  componentDidUpdate(prevProps) {
    let self = this;
    if ((!prevProps || !prevProps.user) && this.props.user) {
      Rest.request('GET', 'developer/users/' + this.props.user.userId, function(status, response) {
        if (Rest.isSuccess(status)) {
          self.setState({publicKey: response.publicKey});
        }
      });
    }
  }
  
  onSelectTab(tabName) {
    this.setState({tab: tabName});
  }
  
  makeTab(tabName) {
    let className = 'tab';
    if (this.state.tab === tabName) {
      className += ' current';
    }
    return (
      <li id={`${tabName}-tab`} className={className} onClick={this.onSelectTab.bind(this, tabName)}></li>
    );
  }
  
  devGames() {
    if (! this.props.user) return [];
    if (! this.props.user.devGames) return [];
    return this.props.user.devGames;
  }
  
  listPanel() {
    if (this.state.tab !== "list") return null;
    // [{"game":"wodka","players":[{"isHost":false,"isCurrent":true,"user":{"userId":16,"username":"user16"},"playerId":0},{"isHost":false,"isCurrent":true,"user":{"userId":17,"username":"user17"},"playerId":1},{"isHost":true,"isCurrent":true,"user":{"userId":15,"username":"user15"},"playerId":2},{"isHost":false,"isCurrent":true,"user":{"userId":18,"username":"user18"},"playerId":3}],"active":true,"sessionId":83},
    return (<div className="panel">
      <h2>Your games</h2>
      <ul>
        {this.devGames().map((game) => (
          <li key={game}>
            <Link to={`/games/${game}#dev`}>{game}</Link> (<Link to={`/games/${game}:dev#dev`}>dev build</Link>)
          </li>
        ))}
      </ul>
      <h2>Register new game</h2>
      <p>For now...just ask sunil to make one for you.</p>
      <h2>Public key</h2>
      <p>A 2048-bit RSA key has been generated for your account. Add this public key to your git repos with read permissions to allow Kangaroo to download your game.</p>
      <textarea className="k-input" value={this.state.publicKey} readOnly></textarea>
    </div>);
  }

  render() {
    return (
      <>
        <div className="tabbed-panel-holder" id="developer-panel">
          <ul className="nav">
            {this.makeTab("list")}
          </ul>
          {this.listPanel()}
        </div>
      </>
    );
  }
}

export default withRouter(DeveloperBox);
