import React from 'react';
import UserContext from '../UserContext.js';
import Rest from '../helpers/Rest.js';
import KangarooAvatar from '../helpers/KangarooAvatar.js';
import { Link, NavLink, withRouter } from 'react-router-dom';

class ActionBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showUserDetailsMenu: false};
    this.handleLogout = this.handleLogout.bind(this);
    this.handleHoverUser = this.handleHoverUser.bind(this);
    this.handleUnhoverUser = this.handleUnhoverUser.bind(this);
  }

  handleLogout(event) {
    event.preventDefault();

    this.setState({showUserDetailsMenu: false});

    let self = this;
    Rest.request('POST', 'accounts/logout', function(status, response) {
      self.props.onLogout();
    });
  }
  
  handleHoverUser(event) {
    if (this.hoverTimeoutId) {
      clearTimeout(this.hoverTimeoutId);
      this.hoverTimeoutId = null;
    }
    this.setState({showUserDetailsMenu: true});
  }
  
  handleUnhoverUser(event) {
    this.hoverTimeoutId = setTimeout(() => this.setState({showUserDetailsMenu: false}), 200);
  }
  
  userDetailsMenu(user) {
    if (user) {
      return (<div id="user-details-menu" className={this.state.showUserDetailsMenu ? '' : 'hidden'} onMouseEnter={this.handleHoverUser} onMouseLeave={this.handleUnhoverUser}>
        <div className="arrow-up"></div>
        {!user.guest &&
          <>
            <NavLink exact to="/profile">Profile</NavLink><br/>
          </>
        }
        {(user.developer &&
          <>
            <NavLink exact to="/developer">Developer Panel</NavLink><br/>
          </>
        )}
        {(user.admin &&
          <>
            <NavLink exact to="/admin">Admin Panel</NavLink><br/>
          </>
        )}
        <span className="fake-link" onClick={this.handleLogout}>Logout</span>
      </div>);
    } else {
      return (<></>);
    }
  }

  rightButtons(user) {
    if (user) {
      return (<span id="user-right-buttons" onMouseEnter={this.handleHoverUser} onMouseLeave={this.handleUnhoverUser}>
          <span>
            {!user.guest && <NavLink exact to="/profile">{user.username}</NavLink>}
            {user.guest &&
              <>
                {user.username}
                <i> (guest)</i>
              </>}
          </span>
          <span onClick={() => {if (! user.guest) {this.props.history.push('/profile')}}}>{KangarooAvatar.makeSvg(50, user)}</span>
        </span>);
    } else {
      // Show a login button
      return (<>
        <span className="fake-link" onClick={() => this.props.setShowLoginModal(true)}>Login</span>
      </>);
    }
  }

  render() {
    return (
      <div id="action-bar">
        <Link to="/"><h1 id="heading-logo">Kangaroo</h1></Link>
        <span id="breadcrumbs">
          {this.props.crumbs.admin && <span>
            <i className="spacer"></i>
            <NavLink exact to={`/admin`}>Admin</NavLink>
          </span>}
          {this.props.crumbs.developer && <span>
            <i className="spacer"></i>
            <NavLink exact to={`/developer`}>Developer</NavLink>
          </span>}
          {this.props.crumbs.game && <span>
            <i className="spacer"></i>
            <NavLink exact to={`/games/${this.props.crumbs.game.tag}`}>{this.props.crumbs.game.name}{
              this.props.crumbs.game.suffix === 'dev' ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b style={{color:"red"}}>DEV BUILD</b></> : ''
            }</NavLink>
          </span>}
          {this.props.crumbs.game && this.props.crumbs.room && <span>
            <i className="spacer"></i>
            <NavLink exact to={`/games/${this.props.crumbs.game.tag}/rooms/${this.props.crumbs.room.roomId}`}>{this.props.crumbs.room.name}</NavLink>
          </span>}
          {this.props.crumbs.game && this.props.crumbs.session && <span>
            <i className="spacer"></i>
            <NavLink exact to={`/games/${this.props.crumbs.game.tag}/sessions/${this.props.crumbs.session.sessionId}`}>Session {this.props.crumbs.session.sessionId}</NavLink>
          </span>}
        </span>
        <span id="right-buttons">
          <UserContext.Consumer>{this.rightButtons.bind(this)}</UserContext.Consumer>
        </span>
        <UserContext.Consumer>{this.userDetailsMenu.bind(this)}</UserContext.Consumer>
      </div>
    );
  }
}

export default withRouter(ActionBar);
