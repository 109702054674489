import React from 'react';
import Rest from '../helpers/Rest.js';

import { withRouter } from 'react-router-dom';

class SpectateLogic extends React.Component {
  get game() {
    return this.props.match.params.game;
  }

  get spectateCode() {
    return this.props.match.params.spectateCode;
  }

  componentDidMount() {
    // Load lobbies and sessions...
    let self = this;

    Rest.request('POST', 'games/' + this.game + '/sessions/spectate/' + this.spectateCode, function(status, response, target) {
      if (Rest.isSuccess(status)) {
        self.props.history.push(`/games/${self.game}/sessions/${response.sessionId}?spectate=${response.spectatedUserId}`);
      } else if (response.sessionId) {
        self.props.history.push(`/games/${self.game}/sessions/${response.sessionId}`);
      } else {
        self.props.history.push(`/games/${self.game}`);
      }
    });
  }

  render() {
    return null;
  }
}

export default withRouter(SpectateLogic);
