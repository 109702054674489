import { Route, Redirect } from "react-router-dom";

function AuthRoute({
  children,
  needAuthToken = true,
  needUser = false,
  ...rest
}) {
  const hasAuth = !needAuthToken || localStorage.getItem("authToken") !== null;
  const hasUser = !needUser || rest.user;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!hasAuth) {
          console.log("setting state from ", location);
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        } else if (!hasUser) {
          return null;
        } else {
          return children;
        }
      }}
    />
  );
}

export default AuthRoute;
