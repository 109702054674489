import React from "react";
import Rest from "../helpers/Rest.js";
import KangarooAvatar from "../helpers/KangarooAvatar.js";
import Game from "../helpers/Game.js";
import { Link, withRouter } from "react-router-dom";

class GamesBox extends React.Component {
  constructor(props) {
    super(props);
    this.onResize = this.onResize.bind(this);
    this.state = {
      mainGames: [],
      otherGames: [],
      topPlayers: [],
      latestWinners: [],
    };
  }

  componentDidMount() {
    let self = this;
    window.addEventListener("resize", this.onResize);
    Rest.request("GET", "games", function (status, response) {
      if (Rest.isSuccess(status)) {
        let mainGames = [];
        let otherGames = [];
        for (let i in response) {
          let game = response[i];
          if (
            game.tag === "wodka" ||
            game.tag === "squareworlds-online" ||
            game.tag === "quizzie" ||
            game.tag === "flume" ||
            game.tag === "seven-hand-poker" ||
            game.tag === "kingsville"
          ) {
            mainGames.push(game);
          } else {
            otherGames.push(game);
          }
        }
        self.setState({ mainGames: mainGames, otherGames: otherGames });
      } else {
        self.props.onError(response);
      }
    });

    Rest.request("GET", "stats/top-players", function (status, response) {
      if (Rest.isSuccess(status)) {
        self.setState({ topPlayers: response });
      }
    });

    Rest.request("GET", "stats/latest-winners", function (status, response) {
      if (Rest.isSuccess(status)) {
        self.setState({ latestWinners: response });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidUpdate() {
    this.onResize();
  }

  hideOverflowingRows(elementId) {
    const panel = document.getElementById(elementId);
    if (panel) {
      const rect = panel.getBoundingClientRect();
      const rows = panel.querySelectorAll("tr");
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowRect = row.getBoundingClientRect();
        const show = rowRect.bottom < rect.bottom;
        row.style.visibility = show ? "visible" : "hidden";
      }
    }
  }

  onResize() {
    this.hideOverflowingRows("latest-winners-panel");
    this.hideOverflowingRows("top-players-panel");
  }

  topPlayers() {
    if (this.state.topPlayers.length === 0) return null;
    return (
      <div id="top-players-panel" className="info-panel grow hide-short-screen">
        <h3>Top players</h3>
        <table className="score-table">
          <tbody>
            {this.state.topPlayers.map((r) => {
              const user = r.userRanking.user;
              const game = new Game(r.game);
              return (
                <tr key={r.game}>
                  <td>
                    <img
                      className="game-icon"
                      width="50"
                      height="50"
                      src={game.iconSource}
                      alt={r.game}
                    />
                  </td>
                  <td>
                    {KangarooAvatar.makeSvg(25, user)} {user.username}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  latestWinners() {
    if (this.state.latestWinners.length === 0) return null;
    return (
      <div
        id="latest-winners-panel"
        className="info-panel grow hide-very-short-screen"
      >
        <h3>Latest winners</h3>
        <table className="score-table">
          <tbody>
            {this.state.latestWinners.map((r) => {
              const game = new Game(r.game);
              return (
                <tr key={r.sessionId}>
                  <td>
                    <img
                      className="game-icon"
                      width="50"
                      height="50"
                      src={game.iconSource}
                      alt={r.game}
                    />
                  </td>
                  <td>
                    {r.winners.map((user, i) => (
                      <React.Fragment key={user.userId}>
                        {i > 0 && <br />}
                        <nobr>
                          {KangarooAvatar.makeSvg(25, user)} {user.username}
                        </nobr>
                      </React.Fragment>
                    ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const discordIcon = require("../img/discord.svg").default;

    return (
      <>
        <div className="centre-outer">
          <div className="centre-left-col">
            <div className="news-col">
              <div className="info-panel grow-very-short-screen">
                <h3>Welcome...</h3>
                <p>
                  ...to a place to play multiplayer games for <i>free</i>.
                </p>
                <p>Grab some friends and check out some of the games!</p>
              </div>
              {this.topPlayers()}
              {this.latestWinners()}
              <div className="info-panel grow-very-short-screen">
                <h3>Community</h3>
                <p>Join the Discord server to meet other players:</p>
                <p className="centred">
                  <a
                    className="plain-link"
                    href="https://discord.gg/hUmXmEn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width="50"
                      height="50"
                      src={discordIcon}
                      alt="Discord server"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="centre-middle">
            <div className="centre-inner-wide">
              <div>
                <div id="main-games" className="space-below">
                  {this.state.mainGames.map((g) => {
                    const game = new Game(g.tag);
                    return (
                      <Link key={g.tag} to={`/games/${g.tag}`}>
                        <img alt={g.tag} src={game.cardSource} />
                      </Link>
                    );
                  })}
                </div>
                <div>
                  {this.state.otherGames.map((game) => (
                    <React.Fragment key={game.tag}>
                      <Link to={`/games/${game.tag}`}>{game.name}</Link>
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(GamesBox);
