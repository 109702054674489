import { Redirect } from 'react-router-dom';

function NotFound() {
  return <div className="centre-outer">
    <div className="centre-middle">
      <div className="centre-inner">
        <h1>404</h1>
        <br/>
        <h2>Page not found!</h2>
        <Redirect to="/" />
      </div>
    </div>
  </div>;
}

export default NotFound;
