import React from "react";
import Rest from "../helpers/Rest.js";
import ExternalOptionsBox from "./ExternalOptionsBox.js";

import { withRouter } from "react-router-dom";

class RoomBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "players",
      submitting: false,
      room: null,
    };
  }

  get game() {
    return this.props.match.params.game;
  }

  get roomId() {
    return this.props.match.params.roomId;
  }

  handleStart() {
    let self = this;
    this.setState({ submitting: true });
    Rest.request(
      "POST",
      "games/" + this.game + "/rooms/" + this.roomId + "/start",
      function (status, response) {
        self.setState({ submitting: false });
        if (!Rest.isSuccess(status)) {
          alert(response.description || "Unknown error.");
        }
      },
      window.options
    );
  }

  handleJoin() {
    // TODO : Doesn't work for rooms with passwords...
    let self = this;
    this.setState({ submitting: true });
    Rest.request(
      "POST",
      "games/" + this.game + "/rooms/" + this.roomId + "/join",
      function (status, response) {
        self.setState({ submitting: false });
        if (status === 200) {
          // Joined room. Go to room page!
          window.location.reload();
        } else {
          alert(response.description || "Unknown error.");
        }
      },
      {}
    );
  }

  handleLeave() {
    let self = this;
    this.setState({ submitting: true });
    Rest.request(
      "POST",
      "games/" + this.game + "/rooms/" + this.roomId + "/leave",
      function (status, response) {
        self.setState({ submitting: false });
        if (Rest.isSuccess(status)) {
          self.props.history.push(`/games/${self.game}`);
        } else {
          alert(response.description || "Unknown error.");
        }
      }
    );
  }

  handleKick(userId) {
    let self = this;
    this.setState({ submitting: true });
    Rest.request(
      "POST",
      "games/" + this.game + "/rooms/" + this.roomId + "/kick",
      function (status, response) {
        self.setState({ submitting: false });
        if (!Rest.isSuccess(status)) {
          alert(response.description || "Unknown error.");
        }
      },
      { userId: userId }
    );
  }

  componentWillUnmount() {
    if (this.roomEventsHandle) {
      this.roomEventsHandle.abort();
    }
    if (window.updateOptions) {
      delete window.updateOptions;
    }
    this.props.setBreadcrumb("room", null);
  }

  componentDidMount() {
    // Load lobbies and sessions...
    let self = this;

    window.updateOptions = function (options) {
      if (!self.state.room) return;
      if (self.state.room.host.userId !== self.props.user.userId) return;
      Rest.request(
        "PUT",
        "games/" + self.game + "/rooms/" + self.roomId + "/options",
        function (status, response) {
          if (!Rest.isSuccess(status)) {
            alert(response.description || "Unknown error.");
          }
        },
        options
      );
    };

    Rest.request(
      "GET",
      "games/" + this.game + "/rooms/" + this.roomId,
      function (status, response, target) {
        if (Rest.isSuccess(status)) {
          if (response.sessionId) {
            if (response.sessionId >= 0) {
              // This room has turned into a session, let's go there instead!
              self.props.history.push(
                `/games/${response.game}/sessions/${response.sessionId}`
              );
            } else {
              // This room was closed, let's go back to game screen.
              self.props.history.push(`/games/${response.game}`);
            }
          } else {
            self.setState({ room: response });
            self.props.setBreadcrumb("room", response);

            let eventId = target.getResponseHeader("X-To-Event-Id");
            if (eventId) {
              self.roomEventsHandle = Rest.listenForEvents(
                "games/" + self.game + "/rooms/" + self.roomId + "/events",
                parseInt(eventId),
                function (event) {
                  if (event.type === "user_join") {
                    // {"type":"user_join","roomId":4,"user":{"user":{"userId":5,"username":"test_user"},"isHost":false}}
                    self.setState((state) => {
                      let r = state.room;
                      let roomDelta = {
                        users: r.users.concat(event.user),
                      };
                      return { room: { ...r, ...roomDelta } };
                    });
                  } else if (event.type === "user_leave") {
                    // {type: "user_leave", roomId: 4, userId: 5}
                    self.setState((state) => {
                      let r = state.room;
                      let roomDelta = {
                        users: r.users.filter(
                          (user) => user.user.userId !== event.userId
                        ),
                      };
                      return { room: { ...r, ...roomDelta } };
                    });
                    if (event.userId === self.props.user.userId) {
                      self.props.history.push(`/games/${response.game}`);
                    }
                  } else if (event.type === "room_gone") {
                    // {"type":"room_gone","roomId":13,"sessionId":1}
                    // {"type":"room_gone","roomId":13}
                    if (event.sessionId) {
                      self.props.history.push(
                        `/games/${self.game}/sessions/${event.sessionId}`
                      );
                    } else {
                      self.props.history.push(`/games/${self.game}`);
                    }
                  } else if (event.type === "room_options") {
                    // {"type":"room_options","roomId":13,"options":{...}}
                    if (typeof window.onUpdateOptions === "function") {
                      window.onUpdateOptions(event.options);
                    }
                  } else if (event.type === "room_created") {
                    // Irrelevant here!
                  }
                }
              );
            }
          }
        }
      }
    );
  }

  makeJoinButton() {
    let rightButtons = (
      <button
        className="join-button"
        disabled={this.state.submitting}
        onClick={this.handleJoin.bind(this, this.props.user.userId)}
      >
        Join
      </button>
    );
    return (
      <div key={this.props.user.userId} className="user user-not-joined">
        <b>{this.props.user.username}</b>
        <span className="right-buttons">{rightButtons}</span>
      </div>
    );
  }

  makeUser(user, room) {
    let isHost = room.host.userId === this.props.user.userId;
    let rightButtons = null;
    if (!user.host && isHost)
      rightButtons = (
        <button
          className="kick-button"
          disabled={this.state.submitting}
          onClick={this.handleKick.bind(this, user.user.userId)}
        >
          Kick
        </button>
      );
    else if (user.user.userId === this.props.user.userId)
      rightButtons = (
        <button
          disabled={this.state.submitting}
          onClick={this.handleLeave.bind(this)}
        >
          Leave
        </button>
      );
    return (
      <div key={user.user.userId} className="user">
        {user.host && <i className="icon icon-crown"></i>}
        {user.user.userId === this.props.user.userId ? (
          <b>{user.user.username}</b>
        ) : (
          " " + user.user.username
        )}
        <span className="right-buttons">{rightButtons}</span>
      </div>
    );
  }

  onSelectTab(tabName) {
    this.setState({ tab: tabName });
  }

  makeTab(tabName, smallScreen = false) {
    let className = "tab";
    if (smallScreen) {
      className += " small-screen";
    }
    if (this.state.tab === tabName) {
      className += " current";
    }
    return (
      <li
        id={`${tabName}-tab`}
        className={className}
        onClick={this.onSelectTab.bind(this, tabName)}
      ></li>
    );
  }

  optionsPanel() {
    if (!this.state.room) return null;
    let className = "";
    if (this.state.tab !== "options") className = " large-screen";
    let startButton = null;
    if (this.state.room.host.userId === this.props.user.userId) {
      startButton = (
        <button id="start-button" onClick={this.handleStart.bind(this)}>
          Start
        </button>
      );
    } else if (
      !this.state.room.users.find(
        (u) => u.user.userId === this.props.user.userId
      )
    ) {
      startButton = <i>Please join the game.</i>;
    } else {
      startButton = <i>Please wait for the host to start the game.</i>;
    }

    return (
      <div className={"right-panel panel" + className}>
        <h1 className="space-below large-screen">Options</h1>
        <div id="options" className="space-below">
          {startButton}
          <ExternalOptionsBox
            game={this.game}
            user={this.props.user}
            room={this.state.room}
          />
        </div>
      </div>
    );
  }

  usersList() {
    if (!this.state.room) return null;
    const users = this.state.room.users.map((user) =>
      this.makeUser(user, this.state.room)
    );
    // If we aren't in the list, then make a fake user we can join with
    if (
      !this.state.room.users.find(
        (u) => u.user.userId === this.props.user.userId
      )
    ) {
      users.push(this.makeJoinButton());
    }
    return users;
  }

  playersPanel() {
    if (!this.state.room) return null;
    let className = "";
    if (this.state.tab !== "players") className = " large-screen";
    return (
      <div className={"left-panel column panel" + className}>
        <h1 className="space-below large-screen">Players</h1>
        <div id="users-list">{this.usersList()}</div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="tabbed-panel-holder small-screen-tabbed-panel-holder">
          <ul className="nav small-screen">
            {this.makeTab("players")}
            {this.makeTab("options")}
          </ul>
          {this.optionsPanel()}
          {this.playersPanel()}
        </div>
      </>
    );
  }
}

export default withRouter(RoomBox);
