import React from 'react';
import Rest from '../helpers/Rest.js';
import { withRouter } from 'react-router-dom';

class ResetBox extends React.Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);

    this.state = { email: '', password: '', confirmPassword: '', error: '', message: '', code: '', submitting: false };

    if (params.get('code')) {
      this.state.code = params.get('code');
      this.state.message = "Please choose a new password for your account.";
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let stateDelta = {};
    stateDelta[event.target.name] = event.target.value;
    this.setState(stateDelta);
  }

  handleResetRequest(event) {
    event.preventDefault();
    this.setState({submitting: true});
    let self = this;
    Rest.request('POST', 'accounts/reset', function(status, response) {
      self.setState({submitting: false});
      if (Rest.isSuccess(status)) {
        // reset request successful, tell them to look at email
        self.setState({ error: "", message: "If an account for that email exists, we have sent a reset code to it. Please check your inbox." });
      } else {
        // Other error!
        self.setState({ error: "Unexpected error.", message: '' });
      }
    }, {email: this.state.email});
  }

  handleReset(event) {
    event.preventDefault();
    let self = this;
    if (this.state.password !== this.state.confirmPassword) {
      self.setState({ error: "Passwords don't match.", message: '' });
      return;
    }

    this.setState({submitting: true});
    Rest.request('POST', 'accounts/reset', function(status, response) {
      self.setState({submitting: false});
      if (Rest.isSuccess(status)) {
        // Password reset
        self.props.history.push('/login', { message: 'Password reset! Please login now.' });
      } else {
        // Other error!
        self.setState({ error: response.description || "Unexpected error", message: '' });
      }
    }, {password: this.state.password, code: this.state.code});
  }

  resetRequestForm() {
    if (this.state.code) return null;
    return (<div id="request-reset-box" className="transparent-box content-box">
      <form id="request-reset-form" onSubmit={this.handleResetRequest.bind(this)}>
        <input id="request-reset-email" name="email" className="k-input" type="email" placeholder="e-mail" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.email} /><br/>
        <button id="request-reset-button" type="submit" disabled={this.state.submitting}>Recover account!</button>
        </form>
    </div>);
  }

  resetForm() {
    if (! this.state.code) return null;
    return (<div id="reset-box" className="transparent-box content-box">
      <form id="reset-form" onSubmit={this.handleReset.bind(this)}>
        <input id="reset-password" name="password" className="k-input" type="password" placeholder="password" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.password} /><br/>
        <input id="reset-password-confirm" name="confirmPassword" className="k-input" type="password" placeholder="confirm password" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.confirmPassword} /><br/>
        <button id="request-button" type="submit" disabled={this.state.submitting}>Reset password!</button>
      </form>
    </div>);
  }

  render() {
    return (
      <div className="centre-outer">
        <div className="centre-middle">
          <div className="centre-inner">
            {this.state.error && <div className="content-box message-box error-box">
              {this.state.error}
            </div>}
            {this.state.message && <div className="content-box message-box success-box">
              {this.state.message}
            </div>}
            {this.resetRequestForm()}
            {this.resetForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetBox);
