import React from 'react';
import Rest from '../helpers/Rest.js';
import BaseComponent from './BaseComponent.js';
import { withRouter } from 'react-router-dom';
import KangarooAvatar from '../helpers/KangarooAvatar.js';

class ProfileBox extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {email: '', password: '', confirmPassword: '', nickname: '', submitting: false, message: null, error: null, changed: false};
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user && ! state.email) {
      return {
        email: props.user.email,
        nickname: props.user.username,
      };
    }
    return null;
  }

  handleSave(event) {
    event.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({error: "Passwords don't match.", message: undefined});
      return false;
    }

    this.setState({submitting: true});
    let self = this;
    let request = {};
    if (this.state.nickname) {
      request.username = this.state.nickname;
    }
    if (this.state.email) {
      request.email = this.state.email;
    }
    if (this.state.password) {
      request.password = this.state.password;
    }
    Rest.request('POST', 'accounts/' + self.props.user.userId, function(status, response) {
      self.setState({submitting: false});
      if (Rest.isSuccess(status)) {
        // update successful
        self.setState({ message: 'User updated.', error: '', changed: false });
        self.props.reloadUser(self.props.user.userId);
      } else if (status === 400) {
        // user error
        self.setState({ message: '', error: response.description, changed: false });
      } else {
        // Other error!
        self.setState({ message: '', error: 'Unexpected server error', changed: false });
      }
    }, request);
  }

  render() {
    return (
      <>
        <div className="centre-outer">
          <div className="centre-middle">
            <div className="centre-inner">
              {this.state.error && <div className="content-box message-box error-box">
                {this.state.error}
              </div>}
              {this.state.message && <div className="content-box message-box success-box">
                {this.state.message}
              </div>}
              <div id="profile-box" className="panel">
                <form id="profile-form" onSubmit={this.handleSave.bind(this)}>
                  <div className="centred" style={{marginBottom: 10, cursor: "pointer"}}>{this.props.user && KangarooAvatar.makeSvg(100, this.props.user)}</div>
                  <label>E-mail</label>
                  <input name="email" className="k-input space-below" type="text" placeholder="e-mail" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.email} />

                  <label>Nickname</label>
                  <input name="nickname" className="k-input space-below" type="text" placeholder="nickname" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.nickname} />
                  <br/>

                  <label>Password</label>
                  <input name="password" className="k-input space-below" type="password" placeholder="new password" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.password} />
                  <br/>

                  <label>&nbsp;</label>
                  <input name="confirmPassword" className="k-input space-below" type="password" placeholder="confirm new password" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.confirmPassword} />
                  <br/>

                  <label>&nbsp;</label>
                  <button id="save-button" disabled={this.state.submitting || ! this.state.changed}>Save</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProfileBox);
