import React from "react";
import Rest from "../helpers/Rest.js";
import KangarooAvatar from "../helpers/KangarooAvatar.js";
import BaseComponent from "./BaseComponent.js";

import { Link, withRouter } from "react-router-dom";

class GameBox extends BaseComponent {
  constructor(props) {
    super(props);

    let startTab = "rooms";
    if (props.location.hash && props.location.hash.length > 0) {
      let potentialTab = props.location.hash.slice(1);
      if (
        ["dev", "rooms", "create-room", "ranking", "info"].includes(
          potentialTab
        )
      ) {
        startTab = potentialTab;
      }
    }

    this.state = {
      tab: startTab,
      submitting: false,
      rankings: [],
      roomName: "",
      roomPassword: "",
      sessions: [],
      rooms: [],
      enteringPasswordForRoomId: null,
      joinRoomPassword: "",
      devInfo: null,
    };

    let names = [
      "Agricola",
      "Bitoku",
      "Concordia",
      "Dominion",
      "Eclipse",
      "A Feast for Odin",
      "Gloomhaven",
      "Heaven and Ale",
      "Istanbul",
      "Jaipur",
      "King of Tokyo",
      "Le Havre",
      "Mage Knight",
      "Neuroshima Hex",
      "Orléans",
      "Puerto Rico",
      "The Quacks of Quedlingburg",
      "Race for the Galaxy",
      "Spirit Island",
      "Terra Mystica",
      "Unfathomable",
      "Vinhos",
      "Wingspan",
      "Xiangqi",
      "Yellow & Yangtze",
      "Zooloretto",
    ];
    this.state.roomName = names[Math.floor(Math.random() * names.length)];

    this.handleCreateBuildFromGit = this.handleCreateBuild.bind(this, "git");
    this.handleCreateBuildFromDev = this.handleCreateBuild.bind(this, "dev");
    this.handleUnpackBuild = this.handleUnpackBuild.bind(this);
    this.handleCreateRoom = this.handleCreateRoom.bind(this);
    this.handleCreateTestSession = this.handleCreateTestSession.bind(this);

    this.onSelectTab(startTab);
  }

  handleCreateBuild(source, event) {
    event.preventDefault();

    if (!this.props.user) {
      // If not logged on, show login box...
      this.props.setShowLoginModal(true);
      return;
    }

    this.setState({ submitting: true });

    let self = this;
    Rest.request(
      "POST",
      "developer/games/" + this.prefix + "/build?source=" + source,
      function (status, response) {
        self.setState({ submitting: false });
        if (status === 200) {
          console.log();
          if (self.state.devInfo) {
            let newBuilds = self.state.devInfo.builds.slice();
            newBuilds.push(response);
            let newDevInfo = { ...self.state.devInfo, builds: newBuilds };
            self.setState({ devInfo: newDevInfo });
          }
        } else {
          alert(response.description || "Unknown error.");
        }
      }
    );
  }

  handleUnpackBuild(event) {
    event.preventDefault();

    if (!this.props.user) {
      // If not logged on, show login box...
      this.props.setShowLoginModal(true);
      return;
    }

    this.setState({ submitting: true });

    let self = this;
    Rest.request(
      "POST",
      "developer/games/" + this.prefix + "/unpackDevBuild",
      function (status, response) {
        self.setState({ submitting: false });
      }
    );
  }

  handleDeployBuild(buildId, event) {
    event.preventDefault();

    if (!this.props.user) {
      // If not logged on, show login box...
      this.props.setShowLoginModal(true);
      return;
    }

    this.setState({ submitting: true });

    let self = this;
    Rest.request(
      "POST",
      "developer/games/" +
        this.prefix +
        "/builds/" +
        buildId +
        "/deploy?deploy-tag=live",
      function (status, response) {
        self.setState({ submitting: false });
        if (Rest.isSuccess(status)) {
          // Reload list
          Rest.request(
            "GET",
            "developer/games/" + self.game,
            function (status, response) {
              if (Rest.isSuccess(status)) {
                self.setState({ devInfo: response });
              }
            }
          );
        } else {
          alert((response && response.description) || "Unknown error.");
        }
      }
    );
  }

  handleCreateRoom(event) {
    event.preventDefault();

    if (!this.props.user) {
      // If not logged on, show login box...
      this.props.setShowLoginModal(true);
      return;
    }

    this.setState({ submitting: true });

    let body = {
      name: this.state.roomName,
    };
    if (this.state.roomPassword) {
      body.password = this.state.roomPassword;
    }
    let self = this;
    Rest.request(
      "POST",
      "games/" + this.game + "/rooms",
      function (status, response) {
        self.setState({ submitting: false });
        if (status === 200) {
          self.props.history.push(
            `/games/${response.game}/rooms/${response.id}`
          );
        } else {
          alert((response && response.description) || "Unknown error.");
        }
      },
      body
    );
  }

  handleCreateTestSession(event) {
    event.preventDefault();

    if (!this.props.user) {
      // If not logged on, show login box...
      this.props.setShowLoginModal(true);
      return;
    }

    this.setState({ submitting: true });

    let self = this;
    Rest.request(
      "POST",
      "games/" + this.game + "/sessions/createtestsession",
      function (status, response) {
        self.setState({ submitting: false });
        if (status === 200) {
          // Go to multi for this new session
          self.props.history.push(
            `/games/${self.game}/sessions/${response.sessionId}?multisession=true`
          );
        } else {
          alert(response.description || "Unknown error.");
        }
      }
    );
  }

  onJoinSession(sessionId) {
    this.props.history.push(`/games/${this.game}/sessions/${sessionId}`);
  }

  onJoinRoom(isPrivate, roomId) {
    let self = this;
    if (!this.props.user) {
      // If not logged on, show login box...
      this.props.setShowLoginModal(true);
    } else {
      if (isPrivate) {
        this.setState({ enteringPasswordForRoomId: roomId });
      } else {
        this.setState({ submitting: true });
        Rest.request(
          "POST",
          "games/" + this.game + "/rooms/" + roomId + "/join",
          function (status, response) {
            self.setState({ submitting: false });
            if (status === 200) {
              // Joined room. Go to room page!
              self.props.history.push(
                `/games/${response.game}/rooms/${response.id}`
              );
            } else {
              alert(response.description || "Unknown error.");
            }
          },
          this.state.joinRoomPassword
            ? {
                password: this.state.joinRoomPassword + "",
              }
            : {}
        );
      }
    }
  }

  get game() {
    return this.props.match.params.game;
  }

  get suffix() {
    let game = this.game;
    if (game.indexOf(":") > 0) {
      return game.substr(game.indexOf(":") + 1);
    }
    return null;
  }

  get prefix() {
    let game = this.game;
    if (game.indexOf(":") > 0) {
      game = game.substr(0, game.indexOf(":"));
    }
    return game;
  }

  componentWillUnmount() {
    if (this.roomEventsHandle) {
      this.roomEventsHandle.abort();
    }
  }

  componentDidMount() {
    // Load lobbies and sessions...
    let self = this;

    if (this.props.user) {
      this.fetchSessions();
    }

    Rest.request(
      "GET",
      "games/" + this.game + "/rooms",
      function (status, response, target) {
        if (Rest.isSuccess(status)) {
          let eventId = target.getResponseHeader("X-To-Event-Id");
          let rooms = [];
          for (var i in response) {
            var room = response[i];
            rooms.push(room);
          }
          self.setState({ rooms: rooms });

          // TODO : Start listening to events here
          if (eventId) {
            self.roomEventsHandle = Rest.listenForEvents(
              "games/" + self.game + "/rooms/events",
              parseInt(eventId),
              function (event) {
                if (event.type === "user_join" || event.type === "user_leave") {
                  // {"type":"user_join","roomId":4,"user":{"user":{"userId":5,"username":"test_user"},"isHost":false},"numUsers":2}
                  // {type: "user_leave", room_id: 4, userId: 5,"numUsers":1}
                  let eventUserId = event.userId || event.user.user.userId;
                  self.setState((state) => {
                    return {
                      rooms: state.rooms.map((r) => {
                        if (r.roomId === event.roomId) {
                          let roomDelta = {
                            numUsers: event.numUsers,
                            maxUsers: event.maxUsers,
                          };
                          if (
                            self.props.user &&
                            eventUserId === self.props.user.userId
                          ) {
                            roomDelta.userInRoom = event.type === "user_join";
                          }
                          return { ...r, ...roomDelta };
                        } else {
                          return r;
                        }
                      }),
                    };
                  });
                } else if (event.type === "room_gone") {
                  // {"type":"room_gone","roomId":13,"sessionId":1}
                  // {"type":"room_gone","roomId":13}
                  self.setState((state) => {
                    return {
                      rooms: state.rooms.filter(
                        (r) => r.roomId !== event.roomId
                      ),
                    };
                  });
                } else if (event.type === "room_created") {
                  // {"type":"room_created","room":{...}}
                  self.setState((state) => {
                    return { rooms: state.rooms.concat(event.room) };
                  });
                }
              }
            );
          }
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      this.fetchSessions();
    }

    window.location.hash = "#" + this.state.tab;

    if ((!prevProps || !prevProps.user) && this.props.user) {
      this.onSelectTab(this.state.tab);
    }
  }

  fetchSessions() {
    let self = this;
    if (!this.props.user) return;
    console.log("fetching sessions");
    Rest.request(
      "GET",
      "games/" + this.game + "/sessions?active=true",
      function (status, response, target) {
        if (Rest.isSuccess(status)) {
          let sessions = [];
          for (let i in response) {
            sessions.push(response[i]);
          }
          self.setState({ sessions: sessions });
        } else {
          alert("Error getting sessions");
        }
      }
    );
  }

  onSelectTab(tabName) {
    this.setState({ tab: tabName });

    if (tabName === "ranking") {
      let self = this;
      if (this.state.rankings.length === 0) {
        if (!this.props.game) return;
        Rest.request(
          "GET",
          "games/" + this.game + "/rankings",
          function (status, response) {
            if (Rest.isSuccess(status)) {
              self.setState({ rankings: response });
            }
          }
        );
      }
    } else if (tabName === "dev" && this.props.user) {
      let self = this;
      if (this.state.devInfo === null) {
        Rest.request(
          "GET",
          "developer/games/" + this.prefix,
          function (status, response) {
            if (Rest.isSuccess(status)) {
              self.setState({ devInfo: response });
            }
          }
        );
      }
    }
  }

  makeTab(tabName, smallScreen = false, requiresUser = false) {
    if (requiresUser && !this.props.user) return null;
    let className = "tab";
    if (smallScreen) {
      className += " small-screen";
    }
    if (this.state.tab === tabName) {
      className += " current";
    }
    return (
      <li
        id={`${tabName}-tab`}
        className={className}
        onClick={this.onSelectTab.bind(this, tabName)}
      ></li>
    );
  }

  makeCirclesHtml(numUsers, maxUsers) {
    if (maxUsers > 5) maxUsers = 5;
    if (numUsers > maxUsers) numUsers = maxUsers;
    let circles = [];
    for (let j = 0; j < numUsers; j++) {
      circles.push(<span key={j} className="circle filled"></span>);
    }
    for (let j = 0; j < maxUsers - numUsers; j++) {
      circles.push(<span key={numUsers + j} className="circle"></span>);
    }
    return <div className="circles">{circles}</div>;
  }

  makeSession(session) {
    let players = "";
    let classes = "";
    let circles = this.makeCirclesHtml(
      session.players.length,
      session.players.length
    );
    for (var i in session.players) {
      if (players.length) players += ", ";
      players += session.players[i].user.username;

      if (session.players[i].user.userId === this.props.user.userId) {
        if (session.players[i].isCurrent) {
          classes += " current";
        }
      }
    }
    return (
      <div
        className={`room session white-icons${classes}`}
        key={`session-${session.sessionId}`}
      >
        <div className="room-info">
          <span className="room-name">
            <i className="icon icon-gamepad"></i> Session {session.sessionId}
          </span>
          <span className="host-name">{players}</span>
        </div>
        <div className="room-info centred">
          <div className="column">
            <div className="player-count">{circles}</div>
            <button
              className="join-room-button"
              onClick={this.onJoinSession.bind(this, session.sessionId)}
            >
              Play
            </button>
          </div>
        </div>
      </div>
    );
  }

  makeRoom(room) {
    let circles = this.makeCirclesHtml(room.numUsers, room.maxUsers);
    let padlock = null;
    if (room.private) {
      padlock = <i className="icon icon-padlock"></i>;
    }
    let classes = "room";
    if (room.userInRoom) {
      classes += " user-in-room white-icons";
    }
    return (
      <div className={classes} key={`room-${room.roomId}`}>
        <div
          className="password-slider"
          style={{
            top:
              this.state.enteringPasswordForRoomId === room.roomId
                ? 0
                : "-100%",
          }}
        >
          <input
            className="k-input"
            type="text"
            placeholder="password"
            name="joinRoomPassword"
            onChange={this.handleChange}
            value={this.state.joinRoomPassword}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
          &nbsp;
          <button
            onClick={this.onJoinRoom.bind(this, false, room.roomId)}
            disabled={this.state.submitting}
          >
            Join
          </button>
          &nbsp;or&nbsp;
          <span
            className="fake-link"
            onClick={() => this.setState({ enteringPasswordForRoomId: null })}
          >
            cancel
          </span>
        </div>
        <div className="room-info">
          <span className="room-name">
            {padlock}
            {room.name}
          </span>
          <span className="host-name">{room.host.username}</span>
        </div>
        <div className="room-info centred">
          <div className="column">
            <div className="player-count">{circles}</div>
            <button
              className="join-room-button"
              onClick={this.onJoinRoom.bind(this, room.private, room.roomId)}
              disabled={this.state.submitting}
            >
              {room.userInRoom ? "Open" : "Join"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  get isAdmin() {
    return this.props.user && (this.props.user.permissions & 1) === 1;
  }

  roomsPanel() {
    if (this.state.tab !== "rooms") return null;
    return (
      <div className="panel column">
        <div className="large-screen">
          {this.manageSection()}
          <form onSubmit={this.handleCreateRoom}>
            <h1 className="space-below">Rooms</h1>

            <label>Room name</label>
            <input
              name="roomName"
              className="k-input space-below"
              type="text"
              placeholder="room name"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onChange={this.handleChange}
              value={this.state.roomName}
            />
            <br />

            <label>Password</label>
            <input
              name="roomPassword"
              className="k-input space-below"
              type="text"
              placeholder="password"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onChange={this.handleChange}
              value={this.state.roomPassword}
            />
            <br />

            <label>&nbsp;</label>
            <button type="submit" disabled={this.state.submitting}>
              Create
            </button>
            {(this.isAdmin || this.isDevForGame()) && (
              <>
                &nbsp;
                <button
                  disabled={this.state.submitting}
                  onClick={this.handleCreateTestSession}
                  className="btn-red"
                >
                  Create test session
                </button>
              </>
            )}
          </form>
        </div>
        <div id="rooms-panel" className="column grow">
          <div className="large-screen space-below"></div>
          <div id="rooms-list">
            {this.state.rooms.length === 0 && this.state.sessions.length === 0 && (
              <>
                <span className="rooms-list-info">
                  There are no rooms right now. Try creating one!
                </span>
              </>
            )}
            {this.state.sessions.map((session) => this.makeSession(session))}
            {this.state.rooms.map((room) => this.makeRoom(room))}
          </div>
        </div>
      </div>
    );
  }

  manageSection() {
    if (this.game === "chess") {
      return (
        <>
          <h1 className="space-below">Extra</h1>
          <Link to={"/games/" + this.game + "/manage"}>Manage boards</Link>
          <br />
          <br />
          <br />
        </>
      );
    }
    return null;
  }

  createRoomPanel() {
    if (this.state.tab !== "create-room") return null;
    if (!this.props.user) return null;
    return (
      <div className="panel">
        <div className="right-panel">
          {this.manageSection()}
          <h1 className="space-below">Create Room</h1>
          <form onSubmit={this.handleCreateRoom}>
            <label>Room name</label>
            <input
              name="roomName"
              className="k-input space-below"
              type="text"
              placeholder="room name"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onChange={this.handleChange}
              value={this.state.roomName}
            />
            <br />

            <label>Password</label>
            <input
              name="roomPassword"
              className="k-input space-below"
              type="text"
              placeholder="password"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onChange={this.handleChange}
              value={this.state.roomPassword}
            />
            <br />

            <label>&nbsp;</label>
            <button type="submit" disabled={this.state.submitting}>
              Create
            </button>
            {(this.isAdmin || this.isDevForGame()) && (
              <>
                &nbsp;
                <button
                  disabled={this.state.submitting}
                  onClick={this.handleCreateTestSession}
                  className="btn-red"
                >
                  Create test session
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    );
  }

  rankingPanel() {
    if (this.state.tab !== "ranking") return null;
    let i = 0;
    return (
      <div className="panel">
        <table className="elegant" id="ranking-table">
          <thead>
            <tr>
              <th>#</th>
              <th className="user-col">Username</th>
              <th>Ranking</th>
            </tr>
          </thead>
          <tbody>
            {this.state.rankings.map((r) => {
              return (
                <tr key={i}>
                  <td>{++i}</td>
                  <td className="user-col">
                    {KangarooAvatar.makeSvg(50, r.user)} {r.user.username}
                  </td>
                  <td>{r.ranking.ranking.toFixed(1)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  statsPanel() {
    if (this.state.tab !== "stats") return null;
    return <div className="panel">Coming soon...</div>;
  }

  infoContent() {
    return (
      <>
        <h1 className="space-below">
          {this.props.game && this.props.game.name}
        </h1>
        {!["flume", "wodka", "kingsville"].includes(this.game) && (
          <p>Rules and information about the game coming "soon".</p>
        )}
        {this.game === "flume" && (
          <p>
            Flume was created by Mark Steere in January, 2010. Other Mark Steere
            games can be found at{" "}
            <a target="_blank" rel="nofollow" href="http://marksteeregames.com">
              marksteeregames.com
            </a>
            .
          </p>
        )}
        {this.game === "kingsville" && (
          <p>
            Rules can be read{" "}
            <a
              target="_blank"
              rel="nofollow"
              href="https://xikka.com/img/blog/93/KingsvilleRules.pdf"
            >
              here
            </a>
            .
          </p>
        )}
        {this.game === "wodka" && (
          <p>
            Rules can be read{" "}
            <a
              target="_blank"
              rel="nofollow"
              href="https://xikka.com/blog/wodka/"
            >
              here
            </a>
            .
          </p>
        )}
      </>
    );
  }

  infoPanel() {
    if (this.state.tab !== "info") return null;
    return <div className="panel">{this.infoContent()}</div>;
  }

  largeInfoPanel() {
    return <div className="panel large-screen">{this.infoContent()}</div>;
  }

  devPanel() {
    if (this.state.tab !== "dev") return null;
    return (
      <div className="panel">
        <h3>Settings</h3>
        {!this.state.devInfo && <p>Loading...</p>}
        {this.state.devInfo && (
          <>
            <ul>
              <li>
                <Link to={"/games/" + this.prefix}>Production build</Link>
              </li>
              <li>
                <Link to={"/games/" + this.prefix + ":dev"}>Dev build</Link>
              </li>
            </ul>
            <h3>Production builds</h3>
            {this.state.devInfo.repoUrl && (
              <>
                <ul>
                  <li>
                    <b>Repo url:</b> <code>{this.state.devInfo.repoUrl}</code>
                  </li>
                  <li>
                    <b>Branch:</b> <code>{this.state.devInfo.branch}</code>
                  </li>
                </ul>
              </>
            )}
            {this.state.devInfo.builds.length === 0 && (
              <p>
                <i>No builds.</i>
              </p>
            )}
            {this.state.devInfo.builds.length > 0 && (
              <table className="elegant" id="builds-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Revision</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.devInfo.builds.map((b) => {
                    return (
                      <tr key={b.id}>
                        <td>
                          {b.deployTags}
                          <button
                            disabled={this.state.submitting}
                            onClick={this.handleDeployBuild.bind(this, b.id)}
                          >
                            Deploy
                          </button>
                        </td>
                        <td>
                          {new Date(b.submittedDate).toDateString()}{" "}
                          {new Date(b.submittedDate).toTimeString()}
                        </td>
                        <td>{b.revision}</td>
                        <td>
                          {b.status === null
                            ? "In progress"
                            : b.status === 0
                            ? "Success"
                            : "Failure"}
                        </td>
                        <td
                          onClick={() => {
                            if (b.log) alert(b.log);
                          }}
                        >
                          {b.log && "Log"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <br />
            {this.state.devInfo.repoUrl && (
              <>
                <button
                  disabled={this.state.submitting}
                  onClick={this.handleCreateBuildFromGit}
                >
                  Create build from repo
                </button>
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            <button
              disabled={this.state.submitting}
              onClick={this.handleCreateBuildFromDev}
            >
              Create build from dev
            </button>
            <br />
            <br />
            <h3>Dev build</h3>
            <br />
            <button
              disabled={this.state.submitting}
              onClick={this.handleUnpackBuild}
            >
              Unpack dev build
            </button>
          </>
        )}
      </div>
    );
  }

  isDevForGame() {
    if (!this.props.user) return false;
    if (!this.props.user.devGames) return false;
    return this.props.user.devGames.includes(this.prefix);
  }

  render() {
    return (
      <>
        <div className="tabbed-panel-holder tabbed-panel-holder-half-large-screen">
          <ul className="nav">
            {this.props.game && this.makeTab("info", true, false)}
            {this.props.game && this.makeTab("rooms")}
            {this.props.game && this.makeTab("create-room", true, true)}
            {this.props.game && this.makeTab("ranking")}
            {this.isDevForGame() && this.makeTab("dev")}
            {/*this.makeTab("stats")*/}
          </ul>
          {this.roomsPanel()}
          {this.createRoomPanel()}
          {this.rankingPanel()}
          {this.statsPanel()}
          {this.infoPanel()}
          {this.devPanel()}
        </div>
        <div className="tabbed-panel-holder left-panel-holder large-screen">
          {this.largeInfoPanel()}
        </div>
      </>
    );
  }
}

export default withRouter(GameBox);
