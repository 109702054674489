import React from 'react';

class BaseComponent extends React.Component {
	constructor(props) {
		super(props);
		
		this.handleChange = this.handleChange.bind(this);
	}
	
	handleChange(event) {
		let stateDelta = {changed: true};
		stateDelta[event.target.name] = event.target.value;
		this.setState(stateDelta);
	}
}

export default BaseComponent;