import "./App.scss";
import React from "react";
import ActionBar from "./components/ActionBar.js";
import AdminBox from "./components/AdminBox.js";
import DeveloperBox from "./components/DeveloperBox.js";
import LoginBox from "./components/LoginBox.js";
import LoginModal from "./components/LoginModal.js";
import ProfileBox from "./components/ProfileBox.js";
import VerifyBox from "./components/VerifyBox.js";
import ResetBox from "./components/ResetBox.js";
import GamesBox from "./components/GamesBox.js";
import GameWrapper from "./components/GameWrapper.js";
import RegisterBox from "./components/RegisterBox.js";
import config from "./config.json";
import UserContext from "./UserContext.js";
import NotFound from "./components/NotFound.js";
import Rest from "./helpers/Rest.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AuthRoute from "./components/AuthRoute.js";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      userId: localStorage.getItem("userId"),
      crumbs: {},
      showLoginModal: false,
      redirectTo: null,
    };

    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onError = this.onError.bind(this);
    this.setShowLoginModal = this.setShowLoginModal.bind(this);

    this.setBreadcrumb = this.setBreadcrumb.bind(this);

    Rest.instance = new Rest(
      config.server,
      localStorage.getItem("authToken"),
      this.onLogout
    );
  }

  componentDidMount() {
    let userId = localStorage.getItem("userId");
    if (userId !== null) {
      // Fetch the user from server
      this.reloadUser(userId);
    }
  }

  reloadUser(userId) {
    let self = this;
    Rest.request("GET", "accounts/" + userId, function (status, response) {
      if (status === 200) {
        self.setState({ user: response });
      } else {
        // No user!
        self.onLogout();
      }
    });
  }

  setBreadcrumb(crumb, value) {
    this.setState((state) => {
      let crumbDiff = {};
      crumbDiff[crumb] = value;
      let stateDiff = {
        crumbs: { ...state.crumbs, ...crumbDiff },
      };
      return { ...state, ...stateDiff };
    });
  }

  onLogin(response) {
    Rest.instance.authToken = response.token;
    localStorage.setItem("authToken", Rest.instance.authToken);
    localStorage.setItem("userId", response.user.userId);
    this.setState({
      user: response.user,
      userId: response.user.userId,
      showLoginModal: false,
      redirectTo: response.from ? response.from.pathname : "/",
    });

    if (response.user.developer) {
      // We should fetch a list of developer game...
      this.reloadUser(response.user.userId);
    }
  }

  onLogout() {
    Rest.instance.authToken = "";
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    this.setState({ user: undefined, userId: null });
  }

  onError(status) {
    Rest.instance.authToken = "";
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    this.setState({ user: undefined, userId: null });
  }

  setShowLoginModal(show) {
    this.setState({ showLoginModal: show });
  }

  loginModal() {
    if (this.state.user) return null;
    if (!this.state.showLoginModal) return null;
    return (
      <LoginModal
        onLogin={this.onLogin}
        setShowLoginModal={this.setShowLoginModal}
      />
    );
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        <Router>
          <div className="App">
            {this.loginModal()}
            <ActionBar
              setShowLoginModal={this.setShowLoginModal}
              onLogout={this.onLogout}
              crumbs={this.state.crumbs}
            />
            <Switch>
              <Route path="/games/:game">
                <GameWrapper
                  key={this.state.userId}
                  setBreadcrumb={this.setBreadcrumb}
                  setShowLoginModal={this.setShowLoginModal}
                />
              </Route>
              <Route path="/">
                <Switch>
                  <Route path="/login">
                    {this.state.user && <Redirect to={this.state.redirectTo} />}
                    <LoginBox onLogin={this.onLogin} />
                  </Route>
                  <Route path="/register">
                    {this.state.user && <Redirect to="/" />}
                    <RegisterBox />
                  </Route>
                  <Route path="/verify">
                    {this.state.user && <Redirect to="/" />}
                    <VerifyBox />
                  </Route>
                  <Route path="/reset">
                    {this.state.user && <Redirect to="/" />}
                    <ResetBox />
                  </Route>

                  <AuthRoute exact path="/profile">
                    <ProfileBox
                      user={this.state.user}
                      reloadUser={this.reloadUser.bind(this)}
                    />
                  </AuthRoute>
                  <AuthRoute exact path="/admin">
                    <AdminBox
                      user={this.state.user}
                      setBreadcrumb={this.setBreadcrumb}
                    />
                  </AuthRoute>
                  <AuthRoute exact path="/developer">
                    <DeveloperBox
                      user={this.state.user}
                      setBreadcrumb={this.setBreadcrumb}
                    />
                  </AuthRoute>
                  <Route exact path="/">
                    <GamesBox
                      key={this.state.userId}
                      onError={this.onError}
                      setBreadcrumb={this.setBreadcrumb}
                    />
                  </Route>
                  <Route component={NotFound} />
                </Switch>
              </Route>
            </Switch>
          </div>
        </Router>
      </UserContext.Provider>
    );
  }
}

export default App;
