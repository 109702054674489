import React from 'react';
import Rest from '../helpers/Rest.js';
import { Link, withRouter } from 'react-router-dom';

class RegisterBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {username: '', nick: '', confirmPassword: '', password: '', error: null, submitting: false};

    this.handleChange = this.handleChange.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }

  handleChange(event) {
    let stateDelta = {};
    stateDelta[event.target.name] = event.target.value;
    this.setState(stateDelta);
  }

  handleRegister(event) {
    event.preventDefault();

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({error: 'Passwords don\'t match.'});
      return;
    }

    let self = this;
    this.setState({submitting: true});
    Rest.request('POST', 'accounts/register', function(status, response) {
      self.setState({submitting: false});

      if (status === 200) {
        self.setState({error: null});
        self.props.history.push('/login', { message: 'Account created! Check your inbox for a verification email.' });
      } else if (status === 400) {
        self.setState({error: response.description});
      } else if (status === -1) {
        self.setState({error: "Could not connect to the server. Please try again later."});
      } else {
        self.setState({error: "Unexpected server error."});
      }
    }, {
      username: this.state.nick,
      email: this.state.username,
      password: this.state.password
    });
  }

  render() {
    return (
      <>
        <div className="centre-outer">
          <div className="centre-middle">
            <div className="centre-inner">
              {this.state.error && <div className="content-box message-box error-box">
                {this.state.error}
              </div>}
              <form onSubmit={this.handleRegister}>
                <div id="login-box" className="transparent-box content-box">
                  <input name="username" className="k-input" type="email" placeholder="email" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.email}/><br/>
                  <input name="nick" className="k-input" type="text" placeholder="nickname" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.email}/><br/>
                  <input name="password" className="k-input" type="password" placeholder="password" autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.email}/><br/>
                  <input name="confirmPassword" className="k-input" type="password" placeholder="confirm password" autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={this.handleChange} value={this.state.email}/><br/>
                  <button type="submit" disabled={this.state.submitting}>Sign up!</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div id="footer-box">
          <Link to="/login">Already have an account?</Link>
        </div>
      </>
    );
  }
}

export default withRouter(RegisterBox);
