class Game {
	constructor(tag, build = 'current') {
		this.tag = tag;
		this.build = build;
	}
	
	get path() {
		return '/gamecontent/' + this.tag + '/current/';
	}
	
	get iframeSource() {
		return this.path + 'index.html?r=' + Math.floor(Math.random() * 10000);
	}
	
	get iconSource() {
		return this.path + 'img/icon.png';
	}
	
	get cardSource() {
		return this.path + 'img/card.png';
	}
}

export default Game;