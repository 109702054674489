import Avatars from "@dicebear/avatars";
import botttsSprites from "@dicebear/avatars-bottts-sprites";
import gridySprites from "@dicebear/avatars-gridy-sprites";
import avataaarsSprites from "@dicebear/avatars-avataaars-sprites";
let parse = require("html-react-parser");

class KangarooAvatar {
  static getSprites(type) {
    let map = {
      bottts: botttsSprites,
      gridy: gridySprites,
      avataaars: avataaarsSprites,
    };
    return map[type] || botttsSprites;
  }
  static makeSvg(size, user, reactElement = true) {
    let type = "bottts";
    let options = {};
    let seed = "" + user.userId;

    if (user.guest) {
      options = {
        colors: ["grey"],
        sidesChance: 0,
        topChance: 0,
        textureChance: 0,
      };
    } else if (user.avatar) {
      if (user.avatar.type) type = user.avatar.type;
      // TODO : Only allow valid options?
      if (user.avatar.options) options = user.avatar.options;
      if (user.avatar.seed) seed = user.avatar.seed;
    }

    let sprites = KangarooAvatar.getSprites(type);

    options.width = size;
    options.height = size;

    let avatars = new Avatars(sprites, options);
    if (reactElement) {
      return parse(avatars.create(seed));
    } else {
      return avatars.create(seed);
    }
  }
}

export default KangarooAvatar;
