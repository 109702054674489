import React from "react";
import Rest from "../helpers/Rest.js";
import KangarooAvatar from "../helpers/KangarooAvatar.js";

import { withRouter } from "react-router-dom";

class SessionBox extends React.Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);

    this.sessionEventsHandles = [];
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      submitting: false,
      multisession: false,
      session: null,
      showLog: false,
      spectateInviteUrl: "",
      currentTab: "log",
      bugTitle: "",
      bugDescription: "",
      bugSent: false,
      bugError: "",
      requestPlayerId: null,
    };

    if (params.get("spectate")) {
      this.state.spectatedUserId = params.get("spectate");
    }

    if (params.get("multisession")) {
      this.state.multisession =
        (props.user.permissions & 1) === 1 && params.get("multisession");
    }
  }

  get suffix() {
    let game = this.game;
    if (game.indexOf(":") > 0) {
      return game.substr(game.indexOf(":") + 1);
    }
    return null;
  }

  get prefix() {
    let game = this.game;
    if (game.indexOf(":") > 0) {
      game = game.substr(0, game.indexOf(":"));
    }
    return game;
  }

  get game() {
    return this.props.match.params.game;
  }

  get sessionId() {
    return this.props.match.params.sessionId || -1;
  }

  handleSwitchPlayer(playerId) {
    this.setState({ requestPlayerId: playerId });

    // Cancel event loaders
    this.sessionEventsHandles.forEach((h) => h.abort());

    // Remove old loader
    document.querySelectorAll(".gamecontent-frame").forEach((e) => e.remove());

    // Add loader
    this.addLoader(this.props.user.userId, playerId);
  }

  handleChange(event) {
    let stateDelta = { changed: true };
    stateDelta[event.target.name] = event.target.value;
    this.setState(stateDelta);
  }

  handleGenerateInviteLink(event) {
    event.preventDefault();
    this.setState({ submitting: true });
    let self = this;
    Rest.request(
      "POST",
      "games/" +
        this.game +
        "/sessions/" +
        this.sessionId +
        "/generatespectateinvite",
      function (status, response) {
        self.setState({ submitting: false });
        if (status === 200 && response.spectateInviteCode) {
          let url =
            "https://kangaroo.games/games/" +
            self.game +
            "/spectate/" +
            response.spectateInviteCode;
          self.setState({ spectateInviteUrl: url });
        }
      }
    );
  }

  handleToggleLog(event) {
    event.preventDefault();
    this.setState((state) => ({
      showLog: !state.showLog,
    }));
  }

  handleSubmitBug(event) {
    let self = this;
    this.setState({ submitting: true });
    Rest.request(
      "POST",
      "bugs/submit",
      function (status, response, target) {
        self.setState({ submitting: false });
        if (Rest.isSuccess(status)) {
          // Replace submitting button with some text
          self.setState({ bugSent: true });
        } else {
          if (response && response.description) {
            self.setState({ bugError: response.description });
          }
        }
      },
      {
        game: this.game,
        title: this.state.bugTitle,
        description: this.state.bugDescription,
        sessionId: this.sessionId,
      }
    );
  }

  handleRewind(event) {
    let self = this;
    this.setState({ submitting: true });
    Rest.request(
      "POST",
      "games/" + this.game + "/sessions/" + this.sessionId + "/rewind",
      function (status, response, target) {
        self.setState({ submitting: false });
        if (Rest.isSuccess(status)) {
          // Reload the page
          window.location.reload(true);
        } else {
          alert("Failed to rewind.");
        }
      }
    );
  }

  selectTab(tabName, event) {
    event.preventDefault();
    this.setState({
      currentTab: tabName,
    });
  }

  componentWillUnmount() {
    this.sessionEventsHandles.forEach((h) => h.abort());
    this.props.setBreadcrumb("session", null);
  }

  componentDidMount() {
    let self = this;
    if (this.sessionId < 0) {
      // Manage mode - add game directly
      this.addLoader(self.props.user.userId);
      return;
    }
    // Load lobbies and sessions...
    let url =
      "games/" +
      this.game +
      "/sessions/" +
      this.sessionId +
      "?with-environment=false";
    if (this.state.spectatedUserId) {
      url += "&spectated-user-id=" + this.state.spectatedUserId;
    }
    Rest.request("GET", url, function (status, response, target) {
      if (Rest.isSuccess(status)) {
        self.setState({ session: response });
        self.props.setBreadcrumb("session", response);

        // Add loader(s)
        if (self.state.multisession) {
          // If this is a multi-session, display all users
          for (let i in response.players) {
            let player = response.players[i];
            self.addLoader(player.user.userId, player.playerId);
          }
        } else {
          // Otherwise, just the one!
          // TODO : allow player to be specified by query param?
          let playerId = 0;
          for (let i in response.players) {
            let player = response.players[i];
            if (
              (self.state.spectatedUserId &&
                player.user.userId === +self.state.spectatedUserId) ||
              (!self.state.spectatedUserId &&
                player.user.userId === self.props.user.userId)
            ) {
              playerId = player.playerId;
              self.setState({ requestPlayerId: player.playerId });
              break;
            }
          }
          self.addLoader(self.props.user.userId, playerId);
        }
      } else {
        // You don't have access
        self.props.history.push(`/games/${self.game}`);
      }
    });
  }

  addLoader(userId, playerId = null) {
    const self = this;
    const frame = document.createElement("IFRAME");
    frame.className = "gamecontent-frame";
    const deployment = this.suffix === "dev" ? "current-dev" : "current";
    const playerSuffix = playerId === null ? "" : " " + playerId;
    frame.src =
      "/gamecontent/" +
      self.prefix +
      "/" +
      deployment +
      "/index.html?r=" +
      Math.floor(Math.random() * 10000);
    frame.onload = function (e) {
      // Load game script
      const win = this.contentWindow;
      const RestWrapper = new Rest(
        Rest.instance.server,
        Rest.instance.authToken + " " + userId + playerSuffix,
        Rest.instance.onUnauthorised
      );
      win.Kangaroo = {
        Rest: RestWrapper,
        turn: function (turn, callback) {
          RestWrapper.request(
            "POST",
            "games/" + self.game + "/sessions/" + self.sessionId + "/turn",
            callback,
            turn
          );
        },
        log: function (message) {
          self.log(message);
        },
        game: self.game,
        sessionId: self.sessionId,
        userId: userId,
        playerId: playerId,
        requestPlayerId: playerId,
        token: Rest.instance.authToken + " " + userId + playerSuffix,
        baseUrl: Rest.instance.server,
        username: self.props.user.username,
        spectatedUserId: self.state.spectatedUserId,
        backToGame: function () {
          self.props.history.push(`/games/${self.game}`);
        },
        renderAvatar: function (playerId) {
          if (!self.state.session || !self.state.session.players) {
            return "";
          }
          for (let i in self.state.session.players) {
            let player = self.state.session.players[i];
            if (player.playerId === playerId) {
              return KangarooAvatar.makeSvg(50, player.user, false);
            }
          }
          return "";
        },
      };

      const sessionBaseUrl =
        "games/" + self.game + "/sessions/" + self.sessionId;
      RestWrapper.request(
        "GET",
        sessionBaseUrl,
        function (status, response, target) {
          if (RestWrapper.isSuccess(status)) {
            // If there is no entry point, we assume the game will make the requests itself
            if (playerId === null) {
              for (let i in response.players) {
                const p = response.players[i];
                if (p.user.userId === userId) {
                  win.Kangaroo.playerId = p.playerId;
                  break;
                }
              }
            } else {
              win.Kangaroo.playerId = playerId;
            }
            if (win.KangarooEntryPoint) {
              win.KangarooEntryPoint.loadSession(response);

              // Listen for events
              let eventId = response.toEventId;
              if (eventId !== null) {
                const handle = RestWrapper.listenForEvents(
                  sessionBaseUrl + "/events",
                  eventId,
                  function (event) {
                    if (event.hasOwnProperty("_type")) {
                      self.handleServerEvent(event);
                    }
                    win.KangarooEntryPoint.handleEvent(event);
                  }
                );
                if (frame.eventListener) {
                  try {
                    frame.eventListener.abort();
                    const index = self.sessionEventsHandles.indexOf(
                      frame.eventListener
                    );
                    if (index >= 0) {
                      self.sessionEventsHandles.splice(index, 1);
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }
                frame.eventListener = handle;
                self.sessionEventsHandles.push(handle);
              }
            }
          } else {
            // Session probably not found, go back to sessions page
            win.Kangaroo.backToGame();
          }
        }
      );
    };
    document.getElementById("game").appendChild(frame);
  }

  log(message) {
    let logHtml = `<div class="log-line">${message}</div>`;
    let logLines = window.document.getElementById("log-lines");
    logLines.innerHTML += logHtml;
    logLines.scrollTo(0, logLines.scrollHeight);
  }

  handleServerEvent(event) {
    if (event._type === "over") {
      // Session is now over
    } else if (event._type === "log") {
      this.log(event.message);
    } else if (event._type === "canResign") {
      // The player may be able to resign now
    }
  }

  selectAll(event) {
    event.target.select();
  }

  spectatePanel() {
    if (this.sessionId < 0) return null;
    if (!this.props.game) return null;
    if (!this.props.game.spectateSupported) return null;
    if (this.state.spectatedUserId) return null;
    return (
      <>
        <h3>
          <i className="icon icon-white icon-glasses" /> Spectate
        </h3>
        <p style={{ fontSize: "smaller" }}>
          Generate a link which you can share with friends to allow them to
          spectate your game.
        </p>
        {this.spectateButton()}
        <br />
        <br />
      </>
    );
  }

  spectateButton() {
    if (this.state.spectateInviteUrl) {
      return (
        <input
          type="text"
          value={this.state.spectateInviteUrl}
          onClick={this.selectAll}
          className="k-input spectate-link"
        />
      );
    } else {
      return (
        <button onClick={this.handleGenerateInviteLink.bind(this)}>
          Invite link
        </button>
      );
    }
  }

  tab(tabName) {
    let selected = this.state.currentTab === tabName;
    return (
      <span
        className={"fake-link " + (selected ? "selected" : "")}
        onClick={this.selectTab.bind(this, tabName)}
      >
        <i
          className={
            "icon " + (selected ? "" : "icon-white") + " icon-" + tabName
          }
        />
      </span>
    );
  }

  tabs() {
    return (
      <>
        {this.tab("log")}
        {this.tab("people")}
        {!this.props.user || this.props.user.guest ? null : this.tab("cog")}
      </>
    );
  }

  hideIfNotTab(tabName) {
    return { display: this.state.currentTab === tabName ? "block" : "none" };
  }

  logTab() {
    return <div id="log-lines" style={this.hideIfNotTab("log")}></div>;
  }

  multiSessionButton() {
    if (this.state.multisession) {
      return (
        <div>
          <a href={"/games/" + this.game + "/sessions/" + this.sessionId + ""}>
            Monosession view
          </a>
        </div>
      );
    }
    if (!this.state.session) return null;
    let matches = 0;
    for (let i in this.state.session.players) {
      const p = this.state.session.players[i];
      if (p.user.userId === this.props.user.userId) {
        matches++;
      }
    }
    if (matches <= 1) {
      return null;
    } else {
      return (
        <div>
          <a
            href={
              "/games/" +
              this.game +
              "/sessions/" +
              this.sessionId +
              "?multisession=true"
            }
          >
            Multisession view
          </a>
        </div>
      );
    }
  }

  peopleTab() {
    return (
      <div id="people-tab" style={this.hideIfNotTab("people")}>
        {this.state.session &&
          this.state.session.players.map((p) => {
            return (
              <div className="person" key={p.playerId}>
                {KangarooAvatar.makeSvg(50, p.user)}
                <h3>
                  {p.user.username}
                  {!this.state.multisession &&
                  p.user.userId === this.props.user.userId &&
                  this.state.requestPlayerId !== p.playerId ? (
                    <>
                      {" "}
                      <button
                        onClick={this.handleSwitchPlayer.bind(this, p.playerId)}
                      >
                        switch
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </h3>
              </div>
            );
          })}
        {this.multiSessionButton()}
      </div>
    );
  }

  cogTab() {
    return (
      <div style={this.hideIfNotTab("cog")} id="advanced-tab">
        {this.spectatePanel()}
        <h3>
          <i className="icon icon-white icon-bug" /> Report bug
        </h3>
        <p style={{ fontSize: "smaller" }}>
          Reporting a bug will save the current and previous gamestate for the
          developer to investigate later.
        </p>
        <input
          type="text"
          placeholder="Bug title"
          className="k-input"
          onChange={this.handleChange}
          value={this.state.bugTitle}
          name="bugTitle"
        />
        <br />
        <textarea
          className="k-input"
          placeholder="Bug details"
          onChange={this.handleChange}
          value={this.state.bugDescription}
          name="bugDescription"
        ></textarea>
        <br />
        {!this.state.bugSent && (
          <>
            <button
              onClick={this.handleSubmitBug.bind(this)}
              disabled={this.state.submitting}
            >
              Submit
            </button>
            <i>{this.state.bugError}</i>
          </>
        )}
        {this.state.bugSent && (
          <p>
            <i>Thank you!</i>
          </p>
        )}
        {/* <br/><br/>
      <h3><i className="icon icon-white icon-door" /> Abandon session</h3>
      <p style={{fontSize: "smaller"}}>
        <p style={{fontSize: "smaller"}}>
          You can abandon a session if it's over a week old. There is no ranking change.
        </p>
        <button>Abandon</button>
      </p> */}
        {(this.props.user.permissions & 1) === 1 && (
          <>
            <br />
            <br />
            <h3>
              <i className="icon icon-white icon-clock" /> Timeline
            </h3>
            <p style={{ fontSize: "smaller" }}>
              <button
                onClick={this.handleRewind.bind(this)}
                disabled={this.state.submitting}
              >
                Rewind 1 step
              </button>
            </p>
          </>
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        <div
          id="game-holder"
          className={`${this.state.showLog ? "" : "log-hidden"} ${
            this.state.multisession ? "multisession" : ""
          }`}
        >
          <div id="game"></div>
          <div id="log">
            <div
              id="tab"
              className="noselect"
              onClick={this.handleToggleLog.bind(this)}
              disabled={this.state.submitting}
            >
              &gt;&gt;
            </div>
            <div id="log-lines-holder">
              <div id="toolbar">{this.tabs()}</div>
              {this.logTab()}
              {this.peopleTab()}
              {this.cogTab()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SessionBox);
