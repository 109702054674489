import React from 'react';
import Rest from '../helpers/Rest.js';
import { Link, withRouter } from 'react-router-dom';

class AdminBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {games: {}, memory: { used: 0, total: 0, free: 0}, threads: 0, tab: "state", sessions: []};
  }
  
  componentDidMount() {
    let self = this;
    this.props.setBreadcrumb('admin', true);
    Rest.request('GET', 'admin/stats', function(status, response) {
      self.setState(response);
    });
    Rest.request('GET', 'admin/sessions', function(status, response) {
      self.setState({sessions: response});
    });
  }

  componentWillUnmount() {
    this.props.setBreadcrumb('admin', null);
  }

  handleRegisterGames(event) {
    event.preventDefault();
    Rest.request('POST', 'admin/register-games', function() {
      window.location.reload();
    });
  }
  
  onSelectTab(tabName) {
    this.setState({tab: tabName});
  }
  
  makeTab(tabName) {
    let className = 'tab';
    if (this.state.tab === tabName) {
      className += ' current';
    }
    return (
      <li id={`${tabName}-tab`} className={className} onClick={this.onSelectTab.bind(this, tabName)}></li>
    );
  }
  
  statePanel() {
    if (this.state.tab !== "state") return null;
    return (<div className="panel">
      <div className="centre-outer">
        <div className="centre-middle">
          <div className="centre-inner">
            <div className="content-box">
              <table>
                <thead>
                  <tr>
                    <th>Game</th>
                    <th>Room responses</th>
                    <th>Session responses</th>
                    <th>Registered at</th>
                  </tr>
                </thead>
                <tbody id="games-table">
                  {Object.keys(this.state.games).map((game) => (
                    <tr key={game}>
                      <td>{game}</td>
                      <td>{this.state.games[game].rooms.total}</td>
                      <td>{this.state.games[game].sessions.total}</td>
                      <td>{this.state.games[game].registeredAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br/>
              <table>
                <tbody id="stats-table">
                  <tr>
                    <td>Memory</td>
                    <td>
                      {this.state.memory.used} / {this.state.memory.total} ({this.state.memory.free} free)
                    </td>
                  </tr>
                  <tr>
                    <td>Threads</td>
                    <td>
                      {this.state.threads}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
  
  optionsPanel() {
    if (this.state.tab !== "options") return null;
    return (<div className="panel">
      <button onClick={this.handleRegisterGames.bind(this)}>Register games</button>
    </div>);
  }
  
  listPanel() {
    if (this.state.tab !== "list") return null;
    // [{"game":"wodka","players":[{"isHost":false,"isCurrent":true,"user":{"userId":16,"username":"user16"},"playerId":0},{"isHost":false,"isCurrent":true,"user":{"userId":17,"username":"user17"},"playerId":1},{"isHost":true,"isCurrent":true,"user":{"userId":15,"username":"user15"},"playerId":2},{"isHost":false,"isCurrent":true,"user":{"userId":18,"username":"user18"},"playerId":3}],"active":true,"sessionId":83},
    return (<div className="panel">
      <h2>Sessions</h2>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Game</th>
            <th>Players</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody id="games-table">
          {this.state.sessions.map((session) => {
            return session && (<tr key={session.sessionId}>
                <td>{session.sessionId}</td>
                <td>{session.game}</td>
                <td>{session.players.map((player) => (<React.Fragment key={player.playerId}>
                  <Link to={`/games/${session.game}/sessions/${session.sessionId}?spectate=${player.user.userId}`}>{player.user.username}</Link>
                  &nbsp;
                </React.Fragment>))}</td>
                <td><Link to={`/games/${session.game}/sessions/${session.sessionId}?multisession=1`}>Multi</Link></td>
              </tr>);
            })}
        </tbody>
      </table>
    </div>);
  }

  render() {
    return (
      <>
        <div className="tabbed-panel-holder" id="admin-panel">
          <ul className="nav">
            {this.makeTab("state")}
            {this.makeTab("options")}
            {this.makeTab("list")}
          </ul>
          {this.statePanel()}
          {this.optionsPanel()}
          {this.listPanel()}
        </div>
      </>
    );
  }
}

export default withRouter(AdminBox);
