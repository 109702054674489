import React from 'react';
import Rest from '../helpers/Rest.js';

import { withRouter } from 'react-router-dom';

class ExternalOptionsBox extends React.Component {
  // Props: game, room
  componentDidMount() {
    // Load lobbies and sessions...
    let self = this;
    window.options = this.props.room.options;
    window.hostId = this.props.room.host.userId;
    window.userId = this.props.user.userId;
    this.pendingScripts = 0;
    Rest.load(`/gamecontent/${this.props.game}/current/options.html?${Math.floor(Math.random() * 10000)}`, (data) => {
      // TODO: Surely this could just be an iframe?
      // Make sure we don't load the app itself (in case of options not found)
      let externalOptions = document.getElementById("external-options");
      externalOptions.innerHTML = data;
      // Eval all script tags
      let scriptTags = externalOptions.getElementsByTagName('script');
      let replacers = [];
      for (let i = 0; i < scriptTags.length; i++) {
        let tag = scriptTags[i];
        if (tag.src) {
          replacers.push(tag);
        }
      }
      for (let i = 0; i < replacers.length; i++) {
        var tag = replacers[i];
        self.pendingScripts++;
        var newScriptTag = document.createElement("SCRIPT");
        newScriptTag.onload = function() {
          self.pendingScripts--;
          if (self.pendingScripts === 0) {
            self.doEval();
          }
        };
        newScriptTag.src = tag.src;
        externalOptions.replaceChild(newScriptTag, tag);
      }
      if (self.pendingScripts === 0) {
        self.doEval();
      }
    });
  }

  doEval() {
    let externalOptions = document.getElementById("external-options");
    let scriptTags = externalOptions.getElementsByTagName('script');
    for (let i = 0; i < scriptTags.length; i++) {
      let tag = scriptTags[i];
      if (tag.innerHTML) {
        // eslint-disable-next-line
        eval.call(window, tag.innerHTML);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    return this.props.game !== nextProps.game;
  }

  render() {
    return (
      <>
        <div id="external-options" />
      </>
    );
  }
}

export default withRouter(ExternalOptionsBox);
