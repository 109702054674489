import React from "react";
import Rest from "../helpers/Rest.js";
import { Link, withRouter } from "react-router-dom";

class LoginBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      nickname: "",
      submitting: false,
      message: null,
      error: null,
      from: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginGuest = this.handleLoginGuest.bind(this);
  }

  componentDidMount() {
    let stateDiff = {};
    if (this.props.location && this.props.location.state) {
      if (this.props.location.state.from) {
        stateDiff.from = this.props.location.state.from;
      }
      if (this.props.location.state.message) {
        stateDiff.message = this.props.location.state.message;
      }
      if (this.props.location.state.error) {
        stateDiff.message = this.props.location.state.error;
      }
    }
    this.setState(stateDiff);
  }

  handleChange(event) {
    let stateDelta = {};
    stateDelta[event.target.name] = event.target.value;
    this.setState(stateDelta);
  }

  handleLogin(event) {
    event.preventDefault();
    this.setState({ submitting: true });

    let self = this;
    Rest.request(
      "POST",
      "accounts/login",
      function (status, response) {
        response.from = self.state.from;
        self.setState({ submitting: false });

        if (status === 200) {
          self.setState({ error: undefined, message: undefined });
          self.props.onLogin(response);
        } else if (status === 400) {
          if (response.code === "login.bad_credentials") {
            self.setState({
              error: (
                <span>
                  {response.description}{" "}
                  <Link to="/reset" onClick={self.hideLoginModal.bind(self)}>
                    Forgot password?
                  </Link>
                </span>
              ),
              message: undefined,
            });
          } else if (response.code === "login.account_unverified") {
            self.setState({
              error: (
                <span>
                  {response.description}{" "}
                  <Link to="/verify" onClick={self.hideLoginModal.bind(self)}>
                    Send another?
                  </Link>
                </span>
              ),
              message: undefined,
            });
          } else {
            self.setState({ error: response.description, message: undefined });
          }
        } else if (status === -1) {
          self.setState({
            error: "Could not connect to the server. Please try again later.",
            message: undefined,
          });
        } else {
          self.setState({
            error: "Unexpected server error.",
            message: undefined,
          });
        }
      },
      {
        credentialType: "password",
        email: this.state.email,
        password: this.state.password,
      }
    );
  }

  handleLoginGuest(event) {
    event.preventDefault();

    if (this.state.nickname.length < 3) {
      this.setState({
        error: "Nickname should be at least 3 characters long.",
        message: undefined,
      });
      return;
    }

    let self = this;
    this.setState({ submitting: true });
    Rest.request(
      "POST",
      "accounts/guest",
      function (status, response) {
        response.from = self.state.from;
        self.setState({ submitting: false });

        if (status === 200) {
          self.setState({ error: undefined, message: undefined });
          self.props.onLogin(response);
        } else if (status === 400) {
          self.setState({ error: response.description, message: undefined });
        } else if (status === -1) {
          self.setState({
            error: "Could not connect to the server. Please try again later.",
            message: undefined,
          });
        } else {
          self.setState({
            error: "Unexpected server error.",
            message: undefined,
          });
        }
      },
      {
        username: this.state.nickname,
      }
    );
  }

  hideLoginModal() {
    if (this.props.setShowLoginModal) {
      this.props.setShowLoginModal(false);
    }
  }

  render() {
    return (
      <>
        <div className="centre-outer">
          <div className="centre-middle">
            <div className="centre-inner">
              {this.state.error && (
                <div className="content-box message-box error-box">
                  {this.state.error}
                </div>
              )}
              {this.state.message && (
                <div className="content-box message-box success-box">
                  {this.state.message}
                </div>
              )}
              <div className="transparent-box content-box">
                <form onSubmit={this.handleLogin}>
                  <input
                    name="email"
                    className="k-input"
                    type="email"
                    placeholder="e-mail"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    onChange={this.handleChange}
                    value={this.state.email}
                  />
                  <br />
                  <input
                    name="password"
                    className="k-input"
                    type="password"
                    placeholder="password"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    onChange={this.handleChange}
                    value={this.state.password}
                  />
                  <br />
                  <button type="submit" disabled={this.state.submitting}>
                    Login!
                  </button>{" "}
                  or{" "}
                  <Link to="/register" onClick={this.hideLoginModal.bind(this)}>
                    sign up
                  </Link>
                </form>
              </div>
              <div id="guest-box" className="transparent-box content-box">
                <form onSubmit={this.handleLoginGuest}>
                  <input
                    name="nickname"
                    className="k-input"
                    type="text"
                    placeholder="nickname"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    onChange={this.handleChange}
                    value={this.state.nickname}
                  />
                  <br />
                  <button type="submit" disabled={this.state.submitting}>
                    Play as guest!
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LoginBox);
