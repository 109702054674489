import React from 'react';
import Rest from '../helpers/Rest.js';
import UserContext from '../UserContext.js';
import AuthRoute from './AuthRoute.js';
import GameBox from './GameBox.js';
import RoomBox from './RoomBox.js';
import SessionBox from './SessionBox.js';
import SpectateLogic from './SpectateLogic.js';
import NotFound from './NotFound.js';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

class GameWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  get game() {
    return this.props.match.params.game;
  }
  
  get prefix() {
    let game = this.game;
    if (game.indexOf(':') > 0) {
      game = game.substr(0, game.indexOf(':'));
    }
    return game;
  }
  
  get suffix() {
    let game = this.game;
    if (game.indexOf(':') > 0) {
      return game.substr(game.indexOf(':') + 1);
    }
    return null;
  }
  
  componentDidUpdate(prevProps) {
    if (this.game !== prevProps.match.params.game) {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    let self = this;
    Rest.request('GET', `games/${this.game}`, function(status, response) {
      if (Rest.isSuccess(status)) {
        self.setState({game: response});
        response.suffix = self.suffix ? self.suffix : '';
        self.props.setBreadcrumb('game', response);
      } else {
        self.setState({game: false});
        self.props.setBreadcrumb('game', {name: self.game, tag: self.prefix, suffix: self.suffix});
      }
    });
  }

  componentWillUnmount() {
    this.props.setBreadcrumb('game', null);
  }

  content(user) {
    if (! this.isDevForGame(user) && ! this.state.game) return null;
    return (
      <Switch>
        <Route exact path={this.props.match.path}>
          <GameBox user={user} game={this.state.game} setShowLoginModal={this.props.setShowLoginModal}/>
        </Route>
        <Redirect exact from={`/games/${this.game}/rooms`} to={`/games/${this.game}`} />
        <Redirect exact from={`/games/${this.game}/sessions`} to={`/games/${this.game}`} />
        
        <AuthRoute exact path={`${this.props.match.path}/rooms/:roomId`} needUser={true} doRedirect={false} user={user}>
          <RoomBox user={user} game={this.state.game} setBreadcrumb={this.props.setBreadcrumb}/>
        </AuthRoute>
        <AuthRoute exact path={`${this.props.match.path}/sessions/:sessionId`} needUser={true} doRedirect={false} user={user}>
          <SessionBox user={user} game={this.state.game} setBreadcrumb={this.props.setBreadcrumb}/>
        </AuthRoute>
        <AuthRoute exact path={`${this.props.match.path}/spectate/:spectateCode`} needUser={true} doRedirect={false} user={user}>
          <SpectateLogic user={user} game={this.state.game} />
        </AuthRoute>
        <AuthRoute exact path={`${this.props.match.path}/manage`} needUser={true} doRedirect={false} user={user}>
          <SessionBox user={user} game={this.state.game} setBreadcrumb={this.props.setBreadcrumb}/>
        </AuthRoute>
      
        <Route component={NotFound}/>
      </Switch>
    );
  }
  
  isDevForGame(user) {
    if (! user) return false;
    if (! user.devGames) return false;
    return user.devGames.includes(this.prefix);
  }

  render() {
    return (
      <UserContext.Consumer>{(user) => (
        <>
          {this.content(user)}
          {/* TODO: Fix. If you aren't logged in, you won't get sent back to homepage. */}
          {(user && ! this.isDevForGame(user) && this.state.game === false) && <NotFound/>}
        </>
      )}</UserContext.Consumer>
    );
  }
}

export default withRouter(GameWrapper);
