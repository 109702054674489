import React from 'react';
import LoginBox from './LoginBox.js';
import { withRouter } from 'react-router-dom';

class LoginModal extends React.Component {	
	onDismiss(event) {
		if (event.target === event.currentTarget || event.target.classList.contains("centre-outer") || event.target.classList.contains("centre-middle") || event.target.classList.contains("centre-inner")) {
			this.props.setShowLoginModal(false);
		}
	}
	
	render() {
		return (
			<div className="modal" onClick={this.onDismiss.bind(this)}>
				<LoginBox onLogin={this.props.onLogin} setShowLoginModal={this.props.setShowLoginModal} />
			</div>
		);
	}
}

export default withRouter(LoginModal);
